.show-right-menu {
  display: none;
}
.app-search .form-control,
.app-search .form-control:focus {
  width: 100% !important;
}
.app-search a {
  right: 5% !important;
}
.leftbar-tab-menu {
  display: block;
}

@media (max-width: 1440px) {
  .hide-right-menu {
    display: none;
  }
  .show-right-menu {
    display: block;
  }
  .leftbar-tab-menu {
    display: none;
  }
}
@media (min-width: 1024px) {
  .top-serach-bar {
    display: flex;
    width: 91%;
  }
  .top-serach-bar .top-serach-items {
    display: inline-table;
  }

  /* .top-serach-bar .top-serach-items .form-control,
  .top-serach-bar .top-serach-items .ui {
    width: 200px !important;
  } */

  .page-content-tab {
    min-height: unset !important;
  }
}

@media (min-width: 1440px) {
  .top-serach-bar {
    width: auto;
  }

  /* .top-serach-bar .top-serach-items .form-control,
  .top-serach-bar .top-serach-items .ui {
    width: 150px !important;
  } */
}
.ml-250 {
  margin-left: 250px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.right-menu-item {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .top-serach-w {
    width: 150px;
  }
}
