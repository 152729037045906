.fig-bg {
  background-color: #17a2b8;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  margin: 3px;
  padding: 2px;
}
.bordered-sec {
  width: 30px;
  height: 100%;
  border: 1px solid gray;
  border-left: 0;
  position: relative;
}
.bordered-sec:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: gray;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.colored {
  width: 10px;
  height: 100%;
  position: relative;
}
.sale-service-section {
  width: 100%;
}
.sale-service-section p {
  margin: 0;
  padding: 0;
}
.sale-service-section .col {
  margin: 30px 0;
}
.sale-service-section .sale-col {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.sale-service-section .sale-col .figures {
  width: 40%;
  height: 100%;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}
.sale-col .figures .row-one {
  flex: 1;
}
.sale-col .figures .fig-row {
  padding: 15px;
}

.colored.clr-blue {
  background-color: rgb(0, 204, 255);
  position: absolute;
  top: 0;
  left: 0;
}
.colored.clr-blue:before {
  content: "";
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0;
  background-color: rgb(1, 153, 203);
}
.colored.clr-blue:after {
  content: "";
  width: 100%;
  height: 5%;
  position: absolute;
  bottom: 0;
  background-color: rgb(0, 102, 202);
}
.sale-col .dates-col {
  display: flex;
  position: relative;
  width: 20%;
}
.dates-col .date-content {
  padding-left: 35px;
}
.dates-col .date-content p {
  position: relative;
}
.left-arrow {
  position: absolute;
  top: 3px;
  left: -20px;
}

.dates-col .difference {
  position: absolute;
  top: 50%;
  left: 120%;
  width: 100%;
  transform: translateY(-20%);
}

.dates-col .bordered-left-col {
  position: absolute;
  right: 0;
  top: 0;
}
.colored.clr-green {
  background-color: rgb(102, 204, 0);
  position: absolute;
  top: 0;
  left: 0;
}
.colored.clr-green:before {
  content: "";
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0;
  background-color: rgb(73, 146, 2);
}
.colored.clr-green:after {
  content: "";
  width: 100%;
  height: 5%;
  position: absolute;
  bottom: 0;
}

@media (max-width: 991px) {
  .sale-col .dates-col {
    width: 30%;
  }
}
