.service-popup {
  padding: 15px;
}
.service-popup p {
  margin: 0;
}
.service-popup a {
  text-decoration: none;
  color: green;
  font-weight: bold;
}
.service-popup button {
  padding: 5px 20px;
  font-weight: bold;
}
