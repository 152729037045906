.loyaltyprinttable th,
.loyaltyprinttable td {
  border: 1px solid #dddddd !important;
}
.table-border {
  border: 1px solid #dddddd !important;
}
.loyaltyprinttable > thead > tr > th {
  background: linear-gradient(#efefef, #dadada);
}
.printMessageBox {
  position: fixed;
  top: 50%;
  text-align: center;
  width: 100px;
  height: 100px;
  font-size: 16px;
  padding: 10px;
  color: #222;
  font-family: helvetica, arial;
  opacity: 1;
  background: #fff;
  background-image: url("../../../resources/images/print.png");
  cursor: pointer;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
