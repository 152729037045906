.premap {
  padding: 25px 0px 0px 25px !important;
}

.btn-active {
  background-color: rgb(0, 174, 239) !important;
  border-color: rgb(150, 147, 147) !important;
  color: #fff !important;
  border: 0px !important;
}
.btn-pr {
  background-color: #f7b636 !important;
  border-color: #f7b636 !important;
  color: #fff !important;
  border: 0px !important;
}
.btn-c {
  background-color: #ee677b !important;
  border-color: #ee677b !important;
  color: #fff !important;
  border: 0px !important;
}
.btn-m {
  background-color: #f39732 !important;
  border-color: #f39732 !important;
  color: #fff !important;
  border: 0px !important;
}
.btn-pm {
  background-color: #235d75 !important;
  border-color: #235d75 !important;
  color: #fff !important;
  border: 0px !important;
}
.btn-s {
  background-color: #4c7759 !important;
  border-color: #4c7759 !important;
  color: #fff !important;
  border: 0px !important;
}
.btn-market {
  background-color: #54ccd5 !important;
  border-color: #54ccd5 !important;
  color: #fff !important;
  border: 0px !important;
}
.btn-all {
  background-color: #3fa89a !important;
  border-color: #3fa89a !important;
  color: #fff !important;
  border: 0px !important;
}
