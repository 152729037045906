@import "../../App.css";
.action-img {
  height: 16px;
}
#pageloader {
  width: 100%;
}

#pageloader img {
  display: table;
  margin: 0 auto;
}
.col-form-label {
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.p-10 {
  padding: 10px !important;
}
.ui.dropdown .menu > .item > .image,
.ui.dropdown .menu > .item > img,
.ui.dropdown > .text > .image,
.ui.dropdown > .text > img {
  margin-top: 0 !important;
  max-height: 1rem !important;
}
.MuiTableCell-root {
  font-size: 1rem !important;
}
