/* custom fonts starts */

body {
  font-family: Poppins-Regular;
  font-size: 16px;
  overflow-x: hidden;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

/* slider starts */

.form-sec {
  width: 600px;
  background: rgba(0, 0, 0, 0.5);
  height: auto;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin: 100px auto 0px auto;
}

/* slider style starts */

#myPCPSlier .carousel-inner .carousel-item {
  height: 100vh;
  background: fixed;
  position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  z-index: -1;
}
.carousel-control-prev-icon {
  background-image: none !important;
}
.carousel-control-next-icon {
  background-image: none !important;
}

#myPCPSlier .carousel-indicators li {
  width: 10px;
  height: 10px;
}

.form-sec .logo {
  margin-top: -80px;
  width: 300px;
}

.form-sec form {
  width: 70%;
}

.form-sec form .form-group {
  width: 100%;
}

.form-sec form .form-group .form-control {
  height: 50px;
  border-radius: 10px;
}

.form-sec form hr {
  border-top: 1px solid rgba(255, 255, 255, 1);
}

.form-sec form .login-btn {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fcce50),
    to(#f5a904)
  );
  border: 0px solid red;
  color: #0c81a7;
  font-weight: bold;
  border-radius: 10px;
  height: 50px;
}

.form-sec form .login-btn:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f5a904),
    to(#fcce50)
  );
  border: 0px solid red;
  color: #0c81a7;
  height: 50px;
}

.form-sec .btn-group .btn-primary {
  transition: 1s;
  background: #0c7fa7;
  border-color: #0c7fa7;
  height: 55px;
}

.form-sec .btn-group .btn-primary:hover {
  background: linear-gradient(
    to bottom,
    rgba(12, 110, 159, 1) 0%,
    rgba(13, 72, 121, 1) 100%
  );
}

/* para sec starts */

.para-sec {
  width: 600px;
  background: rgba(0, 0, 0, 0.5);
  height: auto;
  border-radius: 15px;
  margin: auto;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 1s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
}
#jq_msg {
  position: absolute;
  width: 100%;
  border-radius: 40px;
  z-index: 999;
  background-color: #cd0a0a;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #cd0a0a;
  background: #b81900 50% 50% repeat;
  color: #ffffff;
  border-radius: 15px;
}
.pass-img {
  position: absolute;
  top: 0px;
  left: 50%;
  position: absolute;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.ui-msg p {
  margin-top: 50px;
}
.error-login {
  height: 100px;
}
