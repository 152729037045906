body.dark-sidenav .leftbar-tab-menu .main-icon-menu {
  background-color: #292e40;
  border-right: 1px solid#292e40;
}
.main-menu-inner {
  width: 220px;
  left: 0px;
}

.p-0 {
  padding: 0px;
}
.p-1 {
  padding: 1px;
}

.main-menu-inner .menu-body {
  /* padding: 20px; */
  padding-top: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  height: calc(100vh - 68px) !important;
}

.menu-icon-width {
  width: 18px;
  margin-right: 18px;
}

.leftbar-tab-menu .main-icon-menu .nav-link + .nav-link {
  margin-top: 0px;
}

.main-menu-inner .menu-body .nav-item + .nav-item {
  margin-top: 4px !important;
}
.main-menu-inner .topbar-left .logo .logo-lg {
  height: 24px;
}

.main-menu-inner .topbar-left .logo .logo-lg {
  height: 24px;
}

.anchor_tag_image {
  padding: 0px;
}

.navbar-custom .nav-link-custom {
  padding: 0px;
}

.wizard.vertical > .content {
  margin: 0 0% 0em 0%;
  width: 80%;
}
.qs_file_upload .dropify-wrapper {
  height: 250px !important;
}
.dropify-wrapper {
  height: 142px;
}

.card-body-new {
  padding-top: 0px;
}

.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
  padding-top: 10px;
}

.bottom-fields-upload {
  padding: 0px 20px 0px 20px;
}

.margin-bottom-zero {
  margin-bottom: 0px;
}
.my-1-1 {
  padding-left: 30px;
}

.my-1-2 {
  padding-left: 12px;
}

.form-wizard-wrapper-new label {
  text-align: left;
}

.custom-control-change {
  padding-left: 0rem;
}

.custom-control-lable-change {
  margin-top: 10px;
}

.custom-control-lable-text {
  padding-top: 20px;
}

.p-heading {
  margin-left: 20px;
  font-size: 14px;
  margin-top: 20px;
}

.account-radio-line {
  display: inline-flex !important;
}

.account-radio-row {
  /* display: inline-flex !important; */
  margin-bottom: 10px;
}

.account-radio-text {
  margin-right: 10px;
  margin-top: 3px;
}

.account-radio-text-dss {
  margin-right: 10px;
  margin-top: 3px;
  margin-left: 12px;
}

/* .wizard.vertical > .actions {
   margin-right: 7.5%;
} */

.email-default-row {
  padding-left: 15px;
}
.wizard > .content > .body input.select2-search__field {
  border: 1px solid transparent !important;
}

.wizard > .content > .body ul.fields-list > li {
  list-style: none;
}

.wizard > .content > .body ul.fields-list {
  border: 1px solid;
  padding: 10px;
}

.customer-letter-default {
  margin-top: 130px;
}

.app-settings-checkbox-enable {
  margin-left: 10px;
}

.app-settings-checkbox-disable {
  margin-left: 20px;
}

.customer-contract-box {
  background: #292e40;
  padding: 8px 0px;
  border-radius: 4px;
  height: 100%;
}

div#datatable_filter {
  text-align: right;
}

div#quick-special span.badge {
  line-height: 18px;
  font-size: 14px !important;
}

ul.fields-list {
  border: 1px solid;
  padding: 10px;
  list-style: NONE;
}
div#datatable_paginate ul.pagination {
  float: right !important;
}

.plan-page-badge {
  font-size: 15px !important;
  padding: 10px;
}

#n_gauge_sms_template button.btn.btn-sm.btn-primary.px-4 {
  position: absolute;
  bottom: 30px;
}

#n_gauge_start .nav-justified .nav-item {
  -ms-flex-preferred-size: 0 !important;
  flex-basis: 0 !important;
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
  /* text-align: center; */
}

#n_gauge_active_jobs_section .table th,
.table td {
  border-top: 1px solid transparent;
}

#n_gauge_active_jobs_section .table thead th {
  border-bottom: 2px solid transparent;
}

#n_gauge_active_jobs_section .inner_table thead th {
  border-bottom: 10px solid #175878;
}

#n_gauge_active_jobs_section .card-borader {
  border: 1px solid #01aded;
  width: max-content;
}

#n_gauge_active_jobs_section .col-container {
  display: table;
  width: 100%;
}
#n_gauge_active_jobs_section .col {
  display: table-cell;
}
#marketing_order_history .table th,
.table td {
  border-top: 1px solid transparent;
}
#marketing_order_history .table thead th {
  border-bottom: 2px solid transparent;
}
#marketing_order_history .inner_table thead th {
  border-bottom: 10px solid #175878;
}
#marketing_order_history .card-borader {
  border: 1px solid #01aded;
  width: max-content;
}
#marketing_order_history .col-container {
  display: table;
  width: 100%;
}
#marketing_order_history .col {
  display: table-cell;
}
#marketing_order_history .btn-p0 {
  padding: 0px !important;
}
#marketing_order_history .btn-circle-sm-my {
  width: 10px !important;
}
.item-mask:hover,
.item-mask,
.item-mask:focus,
.item-mask:focus-within,
.item-mask:active,
.item:visited {
  background: none !important;
}
.item-caption-gauge {
  top: 10px;
  position: absolute;
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
}
.table-stripe > tbody > tr:nth-child(4n + 1) {
  background-color: #f1f5fa;
}

.table-stripe > tbody > tr:nth-child(4n + 2) {
  background-color: #f1f5fa;
}
.marketing_order_history td {
  padding: 0px !important;
}
.n-gauge-active-job td {
  padding: 0.15rem !important;
}
.active_job_progress_t {
  height: 150px;
}
.m_history_td .card .card-body,
.history_job_td .card .card-body {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.m_history_td .card,
.history_job_td .card {
  margin-top: 20px;
}
.active_job_progress_t ul li button {
  line-height: 1.2 !important;
}
.fee-border-right {
  border-right: 2px solid #eaf0f7;
}
.fee-border-left {
  border-left: 2px solid #eaf0f7;
}
.no-padding {
  padding: 0px !important;
}
.table_eng_overview td {
  border-top: 1px solid #eaf0f7 !important;
}
.no-l-padding-5px-r-padding {
  padding-left: 0px !important;
  padding-right: 5px !important;
}
.no-right-padding {
  padding-right: 0px !important;
}
.reemed_mini_heading {
  color: #c5c5db;
}
ul.select2-selection__rendered {
  padding-right: 30px !important;
}

.color4d4d4d {
  color: #4d4d4d !important;
}

ul.select2-selection__rendered:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 5px solid #333;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.cus_ds_span {
  margin-left: 14px;
  font-size: 14px;
}
.no-right-padding {
  padding-right: 0px;
}
.td_btns:hover {
  border: 1px solid #cccccc;
  background-color: red;
}
.card-body-padding {
  padding: 0.75rem !important;
}
.chat-schedule-div {
  height: 400px !important;
}
.padding-5px {
  padding-left: 5px;
  padding-right: 5px;
}
.chat_box {
  border: 1px solid #f5f5f5;
}
.chat_bg_box {
  background: #e9f0fa;
}
.chat_box,
.chat_bg_box {
  border-radius: 3px;
}
.font_user_db_chat {
  font-size: 0.6rem;
}
.chat_notification {
  float: right;
  background: red;
  background-color: #30dab5;
  border-radius: 50%;
  padding: 1px 3px 1px 3px;
}
.nav-pills_anchors:hover {
  color: #22bf9d !important;
}
.nav-pills_anchors.active:hover {
  color: #fff !important;
}
.nav-pills-users .nav-link.active {
  background-color: #2ddab5 !important;
} /*#22bf9d*/
.nav-pills-users {
  border: 1px solid #2ddab5;
}
.tab-content-user-sale-rep {
  height: 200px !important;
}

.referral_details_div a {
  margin-left: 15px;
}
.referral_details_div a.collapsed::before {
  content: "\f067";
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 600;
  position: absolute;
  top: -4px;
  left: -27px;
  transition: -webkit-transform 0.25s;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: white;
  font-size: 10px;
  background-color: rgba(80, 110, 228, 0.15);
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  padding-left: 7px;
}
.referral_details_div a::before {
  content: "\f068";
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 600;
  position: absolute;
  top: -4px;
  left: -27px;
  transition: -webkit-transform 0.25s;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: white;
  font-size: 10px;
  background-color: rgba(80, 110, 228, 0.15);
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  padding-left: 7px;
}
.xp_points_collapse {
  padding-left: 20px;
}
.xp_points_collapse::before {
  content: "\f068";
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 600;
  position: absolute;
  top: -4px;
  left: -10px;
  transition: -webkit-transform 0.25s;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #506ee4;
  font-size: 10px;
  background-color: rgba(80, 110, 228, 0.15);
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  padding-left: 7px;
}
.xp_points_collapse.collapsed::before {
  content: "\f067";
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 600;
  position: absolute;
  top: -4px;
  left: -10px;
  transition: -webkit-transform 0.25s;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #506ee4;
  font-size: 10px;
  background-color: rgba(80, 110, 228, 0.15);
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  padding-left: 7px;
}
/*-----------------------Collapsible for User Dashboard------------------------*/
/*.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}*/

/*.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}*/

.glovie_btn,
.chat_app_btn,
.specials_btn,
.loy_cash_btn {
  top: 30px;
  right: -7px;
}

.collapsible_btn {
  position: absolute;
  border-radius: 50%;
  background: white;
  border: 1px solid #b4b7bd;
  line-height: 20px !important;
  font-size: 1rem !important;
}

i,
span {
  display: inline-block;
}
.chat_app_ul li {
  border: 1px solid #7680ff !important;
}
/* ---------------------------------------------------
    Gloves, Specials & loyality Cash Collapse STYLE
----------------------------------------------------- */

#sidebar,
#chat_app_sidebar,
#specials {
  border-radius: 0.25rem;
  border: 1px solid #b4b7bd;
  color: #fff;
  transition: all 0.3s;
}
#loy_cash {
  color: #fff;
  transition: all 0.3s;
}
#loy_cash.active {
  border-radius: 0.25rem;
  border: 1px solid #b4b7bd;
}

#sidebar.active,
#chat_app_sidebar.active,
#specials.active,
#loy_cash.active {
  text-align: center;
  height: 572.5px;
}

.glovie_collapse_icon,
.chat_app_collapse_icon,
.loy_cash_collapse_icon,
.specials_collapse_icon {
  color: #319ccd;
  font-size: 1.3rem !important;
  margin-top: 13rem !important;
}

.glovie_collapse_icon,
.chat_app_collapse_icon,
.loy_cash_collapse_icon,
.specials_collapse_icon {
  display: none;
}
.btn_icon_right {
  display: none;
}

#sidebar.active .glovie_btn,
#chat_app_sidebar.active .chat_app_btn,
#specials.active .specials_btn,
#loy_cash.active .loy_cash_btn {
  right: 0px !important;
}
.col-lg-1 #sidebar.active .sidebar-header .glovie_content,
.col-lg-1 #chat_app_sidebar.active .chat_app_sidebar-header .chat_app_content,
.col-lg-1 #specials.active .specials-header .specials_content,
.col-lg-1 #loy_cash.active .loy_cash-header .loy_cash_content {
  display: none;
}

#sidebar.active .sidebar-header .glovie_content,
#sidebar.active .btn_icon_left,
.glovie_collapse_icon #sidebar.active .CTAs,
#chat_app_sidebar.active .chat_app_sidebar-header .chat_app_content,
#chat_app_sidebar.active .btn_icon_left,
.chat_app_collapse_icon #chat_app_sidebar.active .CTAs,
#specials.active .specials-header .specials_content,
#specials.active .btn_icon_left,
.specials_collapse_icon #specials.active .CTAs,
#loy_cash.active .loy_cash-header .loy_cash_content,
#loy_cash.active .btn_icon_left,
.loy_cash_collapse_icon #loy_cash.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header strong,
#sidebar.active .btn_icon_right,
#sidebar.active .glovie_collapse_icon,
#chat_app_.active .chat_app_sidebar-header strong,
#chat_app_sidebar.active .btn_icon_right,
#chat_app_sidebar.active .chat_app_collapse_icon,
#specials.active .specials-header strong,
#specials.active .btn_icon_right,
#specials.active .specials_collapse_icon,
#loy_cash.active .loy_cash-header strong,
#loy_cash.active .btn_icon_right,
#loy_cash.active .loy_cash_collapse_icon {
  display: block;
}

#sidebar ul li a,
#specials ul li a,
#loy_cash ul li a {
  text-align: left;
}

#sidebar.active ul li a,
#specials.active ul li a,
#loy_cash.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}

#sidebar.active ul li a i,
#specials.active ul li a i,
#loy_cash.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

#sidebar.active ul ul a,
#specials.active ul ul a,
#loy_cash.active ul ul a {
  padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after,
#specials.active .dropdown-toggle::after,
#loy_cash.active .dropdown-toogle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar .sidebar-header,
#specials .specials-header {
  padding: 1rem;
}

#sidebar .sidebar-header strong,
#specials .specials-header strong,
#loy_cash .loy_cash-header strong {
  display: none;
  font-size: 1.8em;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}
@media only screen and (min-width: 1600px) {
  .nav-icon-img {
    margin-left: 10px !important;
  }
}
@media (max-width: 768px) {
  #sidebar,
  #specials {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar.active,
  #specials.active {
    margin-left: 0 !important;
  }
  #sidebar .sidebar-header h3,
  #specials .specials-header h3,
  #sidebar .CTAs,
  #specials .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong,
  #specials .specials-header strong {
    display: block;
  }
  #sidebar ul li a,
  #specials ul li a {
    padding: 20px 10px;
  }
  #sidebar ul li a span,
  #specials ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i,
  #specials ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a,
  #specials ul li a i {
    padding: 10px !important;
  }
  #sidebar ul li a i,
  #specials ul li a i {
    font-size: 1.3em;
  }
  #sidebar,
  #specials {
    margin-left: 0;
  }
  #sidebarCollapse span,
  #specialsCollapse span {
    display: none;
  }
}

/*--------------------------Chat & Appointment------------------------------------------------------------*/

#chat_app_sidebar ul li a {
  text-align: left;
}

#chat_app_sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}

#chat_app_sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

#chat_app_sidebar.active ul ul a {
  padding: 10px !important;
}

#chat_app_sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#chat_app_sidebar .chat_app_sidebar-header {
  padding: 1rem;
}

#chat_app_sidebar .chat_app_sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  padding-left: 30px !important;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  #chat_app_sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #chat_app_sidebar.active {
    margin-left: 0 !important;
  }
  #chat_app_sidebar .chat_app_sidebar-header h3,
  #chat_app_sidebar .CTAs {
    display: none;
  }
  #chat_app_sidebar .chat_app_sidebar-header strong {
    display: block;
  }
  #chat_app_sidebar ul li a {
    padding: 20px 10px;
  }
  #chat_app_sidebar ul li a span {
    font-size: 0.85em;
  }
  #chat_app_sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #chat_app_sidebar ul ul a {
    padding: 10px !important;
  }
  #chat_app_sidebar ul li a i {
    font-size: 1.3em;
  }
  #chat_app_sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
