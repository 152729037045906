#planlist label {
  display: inline-block;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  margin: 0rem 0.5rem;
  padding: 0.2rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: green;
  height: 2rem;
}

#planlist input[type="radio"] {
  display: none !important;
}
#planlist input[type="radio"]:checked + label {
  background-color: #d39e00;
  color: #000;
}
