.formErrorContent {
  width: 100%;
  background: #ee0101;
  position: relative;
  color: #fff;
  min-width: 120px;
  font-size: 11px;
  border: 2px solid #ddd;
  box-shadow: 0 0 6px #000;
  -moz-box-shadow: 0 0 6px #000;
  -webkit-box-shadow: 0 0 6px #000;
  -o-box-shadow: 0 0 6px #000;
  padding: 4px 10px 4px 10px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -o-border-radius: 6px;
}
