.detail_btn {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  border: 0;
}
.detail_btn:hover {
  color: #0056b3;
  text-decoration: underline;
}
