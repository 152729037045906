#redemption-sec p {
  color: #005f7f !important;
}
.img-icon {
  width: 50px;
  padding-left: 10px !important;
  cursor: pointer;
}
#redemption-sec .icons img {
  width: 30px !important;
  margin: 0px 7px !important;
}

#redemption-sec .table th {
  color: #005f7f !important;
  border: 0px solid red !important;
  border-bottom: 2px solid #ccc !important;
}

#redemption-sec .table td {
  border: 0px solid red !important;
}

#redemption-sec .table td .qty .output {
  color: #000 !important;
  line-height: 30px !important;
  border: 1px solid #ccc !important;
  width: 35px !important;
  text-align: center !important;
}

#redemption-sec .table td .qty .plus {
  border-radius: 50% !important;
  background-color: #42ba6e !important;
  border-color: #42ba6e !important;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff !important;
  cursor: pointer !important;
}

#redemption-sec .table td .qty .minus {
  border-radius: 50% !important;
  background-color: #f5707a !important;
  border-color: #f5707a !important;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff !important;
  cursor: pointer !important;
}

#redemption-sec .table td .qty .dot {
  border-radius: 50% !important;
  background-color: #f5707a !important;
  border-color: #f5707a !important;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff !important;
  cursor: pointer !important;
}

#redemption-sec .right-form {
  width: 100% !important;
}

#redemption-sec .right-form .print {
  background-color: #53c5f8 !important;
  border-color: #53c5f8 !important;
}

#redemption-sec .right-form .redeem {
  background-color: #f88700 !important;
  border-color: #f88700 !important;
}

#redemption-sec .refresh {
  background-color: #506ee4 !important;
  border-color: #506ee4 !important;
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  justify-content: center !important;
  margin-right: 5px !important;
  align-items: center !important;
}

#redemption-sec .edit {
  background-color: #42ba6e !important;
  border-color: #42ba6e !important;
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.text_green {
  color: green;
}
.font-s {
  font-size: 12px;
}
