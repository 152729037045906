.orange {
  color: #f88700;
}
.blue {
  color: #29aae1;
}
.purple {
  color: purple;
}
.green {
  color: green;
}
.grayout {
  background-color: #ccc;
}
