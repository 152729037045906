@import "../../../App.css";
.opt-ghost-image {
  position: absolute;
  right: 0px;
  top: 5px;
}
.add-new-bank {
  position: absolute;
  right: 0;
  top: 27px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.right-icons {
  position: absolute;
  top: 8px;
  right: 30px;
}
