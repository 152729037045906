.table thead th {
  border-bottom: 1px solid #dee2e6 !important;
}
#dynamic_xp_points {
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid #29aae1;
  background: #fff;
  color: #000;
}
.btn-points {
  background-color: #5cb85c;
  padding: 0.2em 0.6em 0.3em;
  border-radius: 0.25em;
  color: #fff;
}
.table-color thead {
  background-image: linear-gradient(#f5f5f5, #dddddd);
}
.table-car thead {
  background: #00adee !important;
  color: white;
  text-align: center;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #00adee !important;
}
.redemption-modal {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 30px 30px;
}
